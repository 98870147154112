import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import { Image } from 'src/components/image'

import {
  LinkArrow
} from 'src/components/svgs'

export const ArticleCard = ({ image, title, slug }) => {
  return (
    <div className='col c14 c7--800 article__card c4a--1000 bc bb pb4'>
      <div className='article__card-nested p2 pl0--800 pr0--800 pt2--1000 pb2--1000 df y jcb fdc'>
        <div>
          {image && (
            <Image className='x mt2' imageId={image.imageId}  />
          )}
          <h3 className={cx('mb2 sans mt2', {
            's24--800': image
          })}>{title}</h3>
        </div>
        <div>
          <Link to={`/articles/${slug}`} className='founders link-underline  link-arrow caps'>Read Article <LinkArrow /></Link>
        </div>
      </div>
    </div>
  )
}